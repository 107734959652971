/* eslint-disable no-console */
import React, { useEffect, useState, useRef } from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { Layout } from '../components/layout';
import FilterArrow from '../images/filter-arrow.inline.svg';
import { formatDate } from '../components/formatDate';
import { DividerBlock } from '../components/dividerBlock';
import {
  getUniqueYearList,
  defaultFilterValue,
  useFilters,
  filterItemByYear,
  loadMoreVisibility,
  handleLoadMore,
} from '../helpers/filtering';
import ImageFadeIn from "react-image-fade-in"

const StudiosPage = ({ data }) => {
  const { studio, singleArtist } = data;
  const items = singleArtist.edges;
  const increment = 24;

  const currentYear = new Date().getFullYear();
  const [pastArtists, setPastArtists] = useState([]);

  const [activeItems, setActiveItems] = useState(items);
  const [visibleLimit, setVisibleLimit] = useState(increment);
  const [filters, setFilters] = useState({ year: defaultFilterValue });

  const yearRef = useRef(null);
  const years = getUniqueYearList(items)
    .filter((year) => year < currentYear)
    .sort((a, b) => b - a);

  useEffect(() => {
    setPastArtists(data.pastSingleArtist.edges);
  }, []);

  const handleFilterItems = (artists) => {
    const filteredArtists = artists
      .filter((artist) => filterItemByYear(filters.year, artist));
    setVisibleLimit(increment);
    setActiveItems(filteredArtists);
  };

  const handleFilters = () => {
    setFilters({ year: yearRef.current.value || defaultFilterValue });
  };

  useFilters(filters, handleFilterItems, pastArtists);

  useEffect(() => {
    handleFilters();
  }, []);

  return (
    <Layout bgcolor={studio.backgroundColor} wrapperClass="studios" accentColor={studio.accentColor}>
      <HelmetDatoCms seo={studio.seoMetaTags} />
      <section aria-labelledby="studio" className="main-block">
        <div className="main-title col">
          <h1 id="studio" className="gothic--large large-title">{studio.title}</h1>
        </div>
        <div className="main-image col">
					<Img
            fluid={studio.featuredImage.fluid}
            className="featured-title-image"
            alt={studio.featuredImage.alt}
          /> 
          <p
            className="caption"
            dangerouslySetInnerHTML={{
              __html: studio.featuredImage.title,
            }}
          />
        </div>
        <div className="main-intro row">
          <div
            className="intro-text col sans--regular"
            dangerouslySetInnerHTML={{ __html: studio.introduction }}
          />
          <div className="intro-divider col">
            <DividerBlock accentColor={studio.accentColor} />
          </div>
        </div>
        <div className="content main-text col">
          {studio.description && (
          <section aria-label="Studio program">
            <div
            // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: studio.description,
              }}
            />
          </section>
          )}
          {studio.residenciesDescription && (
            <section aria-label="Residencies program">
              <div
            // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: studio.residenciesDescription,
                }}
              />
              <Link
                className="section-link"
                to="/residencies"
                aria-label="residencies program"
              >
                {studio.residenciesLinkText}
              </Link>
            </section>
          )}

        </div>
      </section>
      {data.currentSingleArtist.edges.length > 0 && (
        <section aria-labelledby="studio-a" className="studio-artists-current container row">
          <div className="col-sm-2 col-xs-6 col">
            <h2 id="studio-a" className="sidebar-text">Studio Artists</h2>
          </div>
          <div className="tiles tiles-4 col-sm-12 col-xs-6 row col spacing-0">
            {data.currentSingleArtist.edges.map(({ node: artist }) => (
              <Link
                to={`/artist/${artist.slug}`}
                key={artist.id}
                className="tile col"
              >
                {artist.profileImage && (
									<ImageFadeIn
										src={artist.profileImage.url}
										alt={artist.profileImage.alt}
										className="tile-image"
									/>               
								)}
                <div className="tile-text-wrap">
                  <span className="artist-name">{artist.name}</span>
                  <span className="artist-dates">
                    {formatDate(artist.startDate)?.year}
                    &nbsp;-&nbsp;
                    {formatDate(artist.endDate)?.year}
                  </span>
                </div>
              </Link>
            ))}
          </div>
          <div className="studios-divider col-sm-5 col-xs-4 offset-xs-1 offset-sm-3 col">
            <DividerBlock accentColor={studio.accentColor.hex} />
          </div>
        </section>
      )}
      <section aria-labelledby="studio-ap" className="studio-artists-past container row">
        <div className="filter-row col-sm-14 col-xs-6 col">
          <h2 id="studio-ap" className="filter-name">Past Studio Artists</h2>
          <div className="filters">
            {years.length > 0 && (
              <label
                className="filter"
                htmlFor="artist-year"
              >
                <span className="filter-text">
                  Year active:
                </span>
                <div className="custom-filter-select">
                  <span>{filters.year}</span>
                  <select
                    id="artist-year"
                    ref={yearRef}
                    onChange={() => { handleFilters(); }}
                  >
                    <option value={defaultFilterValue}>{defaultFilterValue}</option>
                    {years.map((val) => (
                      <option key={val} value={val}>{val}</option>
                    ))}
                  </select>
                  <FilterArrow />
                </div>
              </label>
            )}
          </div>
        </div>
        {activeItems.length > 0 && (
          <>
            <div className="col-sm-2 col-xs-6 col">
              <h2 id="studio-ap" className="sidebar-text filter">Past Studio Artists</h2>
            </div>
            <div className="tiles tiles-6 col-sm-12 col-xs-6 col row spacing-0">
              {activeItems.map(({ node: artist }, i) => (
                <Link to={`/artist/${artist.slug}`} key={artist.id} className="tile col" style={{ display: `${loadMoreVisibility(i, visibleLimit)}` }}>
                  {artist.profileImage && (
                    <ImageFadeIn
										src={artist.profileImage.url}
										alt={artist.profileImage.alt}
										className="tile-image"
										/> 
                  )}
                  <div className="tile-text-wrap">
                    <span className="artist-name">{artist.name}</span>
                    <span className="artist-dates">
                      {formatDate(artist.startDate)?.year}
                      &nbsp;-&nbsp;
                      {formatDate(artist.endDate)?.year}
                    </span>
                  </div>
                </Link>
              ))}
              <div className="container col row">
                {activeItems.length > visibleLimit && <button onClick={() => { handleLoadMore(setVisibleLimit, visibleLimit, increment); }} type="button" aria-label="Load more" className="btn show-more">Load more</button>}
              </div>
            </div>
            <div className="col-sm-5 col-xs-4 offset-xs-2 offset-sm-8 col">
              <DividerBlock accentColor={studio.accentColor.hex} />
            </div>
          </>
        )}
      </section>
    </Layout>
  );
};

StudiosPage.propTypes = {
  data: PropTypes.objectOf(PropTypes.shape()).isRequired,
};

export default StudiosPage;

export const query = graphql`
  query StudioAllQuery {
    studio: datoCmsStudio {
      backgroundColor
      accentColor {
        hex
      }
      description
      title
      residenciesDescription 
      residenciesLinkText
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      introduction
      featuredImage {
        title
				fluid(maxHeight: 800, imgixParams: { fm: "jpg" }) {
          ...GatsbyDatoCmsSizes_noBase64
        }				
				alt
      }
    }
    singleArtist: allDatoCmsArtist(
      sort: {
        fields: startDate,
        order: DESC
      },
      filter: {meta: {isValid: {eq: true}, status: { ne: "draft" }}}
    ) {
      edges {
        node {
          profileImage {
						url(imgixParams: { w: "500" h: "500", fit: "crop", crop: "focalpoint" })
						alt
          }
          id
          name
          slug
          startDate
          endDate
          model {
            name
          }
        }
      }
    }
    currentSingleArtist: allDatoCmsArtist(
      sort: {
        fields: startDate,
        order: DESC
      },
      filter: {studioArtistStatus: {eq: "CURRENT"}, meta: {isValid: {eq: true}, status: { ne: "draft" }}}
    ) {
      edges {
        node {
          profileImage {
						url(imgixParams: { w: "500" h: "500", fit: "crop", crop: "focalpoint" })
						alt
          }
          id
          name
          slug
          startDate
          endDate
          model {
            name
          }
        }
      }
    }
    pastSingleArtist: allDatoCmsArtist(
      sort: {
        fields: startDate,
        order: DESC
      },
      filter: {studioArtistStatus: {ne: "CURRENT"}, meta: {isValid: {eq: true}, status: {ne: "draft"}}}
    ) {
      edges {
        node {
          profileImage {
						url(imgixParams: { w: "400" h: "400", fit: "crop", crop: "focalpoint" })
						alt
          }
          id
          name
          slug
          startDate
          endDate
          model {
            name
          }
        }
      }
    }
  }
`;
